import { DialogClose } from "@/components/ui/dialog"
import React from "react"
import { useForm } from "react-hook-form"
import TextInputLabel from "../../components/form/TextInputLabel"
import ModalButton from "../../components/modal-button/ModalButton"
import ThruModal from "../../components/thru-modal/ThruModal"
import { ThruFormProvider } from "../../context/ThruFormContext"
import { forgotPassword, forgotUsername } from "../../utils/ForgotUserClient"
import { useCloseModal } from "../../utils/useCloseModal"
import { useModalHelper } from "../../utils/useModalHelper"
import { useForgotCredentials } from "./useForgotCredentials"

interface IForgotLoginModal {
  modalId: string
  title: string
  prompt: string
  inputLabel: string
  inputPlaceholder: string
  validate?: any
}

const ForgotLoginModal: React.FC<IForgotLoginModal> = ({
  modalId,
  title,
  prompt,
  inputLabel,
  inputPlaceholder,
  validate,
}: IForgotLoginModal) => {
  const isForgotUsername = modalId === "forgotUsernameModal"

  const { submitData, inputs } = useForgotCredentials(
    isForgotUsername ? forgotUsername : forgotPassword,
    isForgotUsername
      ? "If you have an active user account, we've sent you an email containing all usernames associated with that email. Can't find the email? Try checking your spam folder. If you still can't log in, have us resend the email or contact support."
      : "If you have an active user account, we've sent you an email with a link to finish resetting your password. Can't find the email? Try checking your spam folder. If you still can't log in, have us resend the email or contact support.",
  )
  const { closeModal } = useModalHelper({ modalId })
  useCloseModal(modalId)

  const form = useForm()
  const { handleSubmit } = form

  const onSubmit = async (data: any) => {
    await submitData({
      username: data[inputs.USERNAME],
      emailAddress: data[inputs.EMAIL_ADDRESS],
    })
    closeModal()
  }

  return (
    <ThruFormProvider customForm={form} loading={false} onSubmit={handleSubmit(onSubmit)} preventUnsavedChanges={false}>
      <ThruModal
        title={title}
        id={modalId}
        centered
        modalClass="recover-modal"
        modalSize="lg"
        buttons={[
          <ModalButton
            variant="default"
            key={0}
            id={`${modalId}-forgot-login-send`}
            type="submit"
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            disabled={!form.formState.isDirty}
          >
            Send
          </ModalButton>,
          <DialogClose asChild key={1}>
            <ModalButton id={`${modalId}-forgot-login-cancel`}>Cancel</ModalButton>
          </DialogClose>,
        ]}
      >
        <div className="space-y-4" style={{ padding: 24 }}>
          <p>{prompt}</p>
          <div className="mb-0">
            <TextInputLabel
              label={inputLabel}
              name={isForgotUsername ? inputs.EMAIL_ADDRESS : inputs.USERNAME}
              validate={(value: any) => validate?.(value)}
              placeholder={inputPlaceholder}
              required
            />
          </div>
        </div>
      </ThruModal>
    </ThruFormProvider>
  )
}

export default ForgotLoginModal

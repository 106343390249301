import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { cn } from "@/lib/utils"
import { useViewPermission } from "@/src/context/AuthContext"
import { faCopy } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TextInput as TextInputMantine } from "@mantine/core"
import React, { KeyboardEvent } from "react"
import { useFormContext, useFormState, useWatch } from "react-hook-form"
import { toast } from "react-toastify"
import { useThruFormContext } from "../../context/ThruFormContext"
import { MaterialButton } from "../material-button/MaterialButton"
import SkeletonFormControl from "../skeleton-form-control/SkeletonFormControl"

export interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  description?: string
  label?: string
  textArea?: boolean
  valueAsNumber?: boolean
  showCopyButton?: boolean
  validate?: any
  rows?: number
  skeletonHeight?: number
  icon?: JSX.Element
  rightSection?: JSX.Element
}

const digitsOnlyType = "digitsOnly"
const defaultRows = 4
const defaultMaxLength = 256

type IClipboardCopyForm = {
  name: string
  value?: string
  defaultValue?: string
}

export const ClipboardCopyForm: React.FC<IClipboardCopyForm> = ({ name, value, defaultValue }) => {
  const copyValue = useWatch({ name, defaultValue: value ?? defaultValue })

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyValue).then(
      () => toast.success("Copied to clipboard!"),
      () => toast.error("Failed to copy to clipboard!"),
    )
  }

  return (
    <MaterialButton>
      <Button type="button" size="icon" className="mb-0 rounded-l-none shrink-0" onClick={copyToClipboard}>
        <FontAwesomeIcon icon={faCopy} />
      </Button>
    </MaterialButton>
  )
}

type IClipboardCopy = {
  value: string
}

export const ClipboardCopy: React.FC<IClipboardCopy> = ({ value }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value).then(
      () => toast.success("Copied to clipboard!"),
      () => toast.error("Failed to copy to clipboard!"),
    )
  }

  return (
    <MaterialButton>
      <Button type="button" size="icon" className="mb-0 rounded-l-none shrink-0" onClick={copyToClipboard}>
        <FontAwesomeIcon icon={faCopy} />
      </Button>
    </MaterialButton>
  )
}

const TextInput: React.FC<ITextInput> = ({
  name,
  textArea,
  valueAsNumber,
  validate,
  rows = defaultRows,
  showCopyButton,
  maxLength = defaultMaxLength,
  skeletonHeight,
  ...rest
}) => {
  const { register } = useFormContext()
  const isArrayInput = name.includes("[")
  let errorKey = name
  if (isArrayInput) {
    errorKey = name.substring(0, name.indexOf("["))
  }
  const { errors } = useFormState({ name: errorKey })
  const { loading, requiredPermissionsForWrite } = useThruFormContext()
  const writeAllowed = useViewPermission(requiredPermissionsForWrite)
  let error = errors[errorKey]

  if (isArrayInput && !!error) {
    const arrayIndex = +name.substring(name.indexOf("[") + 1, name.indexOf("]"))
    const arrayEl = (error as any)[arrayIndex]
    if (!!arrayEl) {
      const arrayName = name.substring(name.indexOf("]") + 2)
      error = (error as any)[arrayIndex][arrayName]
    } else {
      error = undefined
    }
  }

  const inputProps = {
    ...register(name, {
      required: { value: rest.required ?? false, message: "Please supply a value." },
      valueAsNumber,
      validate,
    }),
  }

  const handleChange = (e: any) => {
    rest.onChange?.(e)
    inputProps.onChange(e)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (rest.type === digitsOnlyType && !e.key.match(/[0-9]/)) {
      e.preventDefault()
    }
  }

  const getInputComponent = () => {
    const commonProps = {
      ...rest,
      ...inputProps,
      id: rest.id ?? name,
      type: rest.type === digitsOnlyType ? "text" : rest.type,
      maxLength,
      placeholder: rest.placeholder,
      readOnly: writeAllowed ? rest.readOnly : true,
      className: cn(!!error && "validationError", rest.className),
      onChange: handleChange,
      onKeyPress: handleKeyPress,
      [`data-lpignore`]: true,
    }

    if (!!rest.description) {
      return (
        <TextInputMantine
          {...(commonProps as any)}
          className={`form-mantine${error ? "validationError" : ""}`}
          labelProps={{ style: { fontWeight: 400 } }}
          onBlur={(e: any) => {
            e.target.value = e.target.value.trim()
            commonProps.onBlur && commonProps.onBlur(e)
          }}
        />
      )
    }

    if (textArea) {
      return <Textarea {...(commonProps as any)} rows={rows} />
    }

    return <Input {...commonProps} className={cn("w-full", showCopyButton && "rounded-r-none")}></Input>
  }

  return (
    <SkeletonFormControl loading={loading ?? false} inline height={skeletonHeight}>
      {getInputComponent()}
      {showCopyButton && (
        <ClipboardCopyForm name={name} value={rest.value?.toString()} defaultValue={rest.defaultValue?.toString()} />
      )}
      {error?.message &&
        error.message
          .toString()
          .split("\n")
          .map((msg: string, i: number) => (
            <small key={i} className="form-text danger-color">
              {msg}
            </small>
          ))}
    </SkeletonFormControl>
  )
}

export default TextInput

import { TextTooltip } from "@/components/ui/text-tooltip"
import React from "react"

interface ITooltip {
  title: string
  children: React.ReactNode
  tooltipEnabled?: boolean
}

const Tooltip: React.FC<ITooltip> = ({ title, children, tooltipEnabled = true }: ITooltip) => {
  if (!children) {
    return <></>
  }

  if (tooltipEnabled) {
    return <TextTooltip title={title}>{children}</TextTooltip>
  }

  return <>{children}</>
}

export default Tooltip

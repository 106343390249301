import { useCallback, useLayoutEffect } from "react"
import { useModalPrompt } from "../components/modal-prompt/useModalPrompt"
const $ = (window as any).$

interface IModalHelper {
  modalId: string
  onOpenModal?: () => void
  onHideModal?: () => void
}

export const useModalHelper = ({ modalId, onOpenModal, onHideModal }: IModalHelper) => {
  const renderKey = useModalPrompt((state) => state.renderKey)

  const openModal = useCallback(() => {
    document.getElementById(`${modalId}-open`)?.click()
  }, [modalId])
  const closeModal = useCallback(() => {
    document.getElementById(`${modalId}-close`)?.click()
  }, [modalId])

  // Trigger on open modal notification
  useLayoutEffect(() => {
    const listener = (e: any) => {
      if (e.detail.id !== modalId) return
      onOpenModal?.()
    }

    document.addEventListener("shown.bs.modal", listener)

    return () => {
      document.removeEventListener("shown.bs.modal", listener)
    }
  }, [onHideModal, modalId, onOpenModal, renderKey])

  // Trigger on hide modal notification
  useLayoutEffect(() => {
    const listener = (e: any) => {
      if (e.detail.id !== modalId) return
      onHideModal?.()
    }

    document.addEventListener("hide.bs.modal", listener)

    return () => {
      document.removeEventListener("hide.bs.modal", listener)
    }
  }, [onHideModal, modalId, onHideModal, renderKey])

  return { openModal, closeModal }
}

import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  modalHeaderVariants,
  modalPlacementVariants,
  modalSizeVariants,
} from "@/components/ui/dialog"
import { cn } from "@/lib/utils"
import { VariantProps } from "class-variance-authority"
import React, { ReactNode } from "react"
import * as portals from "react-reverse-portal"
import { useModalPortal } from "../../context/ModalPortalContext"
import { useCloseModal } from "../../utils/useCloseModal"

interface IThruModal {
  id: string
  title: ReactNode
  buttons?: ReactNode[]
  headerVariant?: VariantProps<typeof modalHeaderVariants>["variant"]
  modalClass?: string
  modalContentClass?: string
  modalBodyProps?: any
  modalSize?: VariantProps<typeof modalSizeVariants>["size"]
  modalAlignment?: VariantProps<typeof modalPlacementVariants>["align"]
  modalFooter?: ReactNode
  children: ReactNode
  scrollable?: boolean
  centered?: boolean
  staticModal?: boolean
  onClose?: () => void
}

const ThruModal: React.FC<IThruModal> = ({
  id,
  title,
  buttons,
  modalClass = "",
  modalContentClass = "",
  modalBodyProps,
  modalSize = "default",
  modalAlignment,
  modalFooter,
  headerVariant,
  children,
  scrollable,
  centered,
  staticModal,
  onClose,
}: IThruModal) => {
  useCloseModal(id)

  const { portalNode } = useModalPortal()

  return (
    <portals.InPortal node={portalNode}>
      <Dialog
        defaultOpen={false}
        onOpenChange={(open) => {
          // Fire shown.bs.modal custom dom event
          if (open) {
            const event = new CustomEvent("shown.bs.modal", {
              bubbles: true,
              cancelable: true,
              detail: {
                id: id,
              },
            })
            document?.dispatchEvent(event)
          } else {
            // Fire hide.bs.modal custom dom event
            const event = new CustomEvent("hide.bs.modal", {
              bubbles: true,
              cancelable: true,
              detail: {
                id: id,
              },
            })
            document?.dispatchEvent(event)
            onClose?.()
          }
        }}
      >
        <DialogTrigger asChild>
          <Button className="hidden" id={`${id}-open`}>
            Open
          </Button>
        </DialogTrigger>
        <DialogClose asChild>
          <Button className="hidden" id={`${id}-close`}>
            Close
          </Button>
        </DialogClose>
        <DialogContent
          {...modalBodyProps}
          size={modalSize}
          align={modalAlignment}
          id={id}
          className={cn(modalClass, modalBodyProps?.className)}
        >
          <DialogHeader variant={headerVariant}>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>

          {children}
          {(buttons?.length ?? 0) > 0 && (
            <DialogFooter>
              {React.Children.map(buttons, (button, index) => {
                return React.cloneElement(button as React.ReactElement, {
                  key: index,
                })
              })}
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </portals.InPortal>
  )
}

export default ThruModal

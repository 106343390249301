import { cn } from "@/lib/utils"
import { useLoaderStore } from "@/src/utils/useLoader"
import "./Spinner.css"

const Spinner = () => {
  const isLoading = useLoaderStore((state) => state.loadingComponents.size > 0)

  return (
    <div
      className={cn(
        "page-overlay-wrapper flex items-center justify-center opacity-0 transition-opacity duration-200",
        isLoading && "opacity-100",
        !isLoading && "pointer-events-none",
      )}
    >
      <div className="spinner-border bee-spinnerx" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Spinner
